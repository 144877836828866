import barcode_scanner_beep from "../media/barcode-scanner-beep-sound.mp3";
import default_ringing_sound from "../media/ring01.mp3";

export const SOUNDS_MAPPING = {
  ringing: {
    file: default_ringing_sound,
  },
  beep: {
    file: barcode_scanner_beep,
  }
};
