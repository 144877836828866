import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import QrCode2 from '@mui/icons-material/QrCode2';
import { local } from "brownies";
import { BarcodeReader, BarcodeScanner, EnumBarcodeFormat, TextResult } from "dynamsoft-javascript-barcode";
import React, { CSSProperties, Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { SOUNDS_MAPPING } from "../../assets/static";
import { DriverLicenseParser, PersonInfo } from "./DriversLicenseParser";
import './DynamsoftScanner.css';

export interface DynamsoftScannerProps {
  license?: string;
  beep?: boolean;
  open?: boolean;
  title?: string;
  onSuccessfullScan?: (personIfo: PersonInfo) => void;
}

const DynamsoftScanner: React.FC<DynamsoftScannerProps> = ({ open = false, onSuccessfullScan, license, beep = true, title="" }: DynamsoftScannerProps): ReactElement => {

  const scannerRef = useRef<HTMLDivElement>();
  const [scannerOpen, setScannerOpen] = useState(open);

  const dialogButton: CSSProperties = {
    minWidth: "100px",
    marginTop: "60px",
    marginBottom: "20px",
    marginRight: "10px"
  };
  
  let scanner: BarcodeScanner;
  let audio = new Audio();

  const initScanner = async () => {

    try {
      if (!BarcodeReader.license) {
        BarcodeReader.license = license || "";
        BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.0.0/dist/";
      }
      scanner = await BarcodeScanner.createInstance();
      const settings = await scanner.getRuntimeSettings();
      const cameras = await scanner.getAllCameras();
      if (cameras.length) {
        const lastCameraSelected = cameras.find(camera => camera.deviceId == local.lastCameraId)
        const defaultCamera = lastCameraSelected || cameras[0];
        await scanner.setCurrentCamera(defaultCamera);
      }
      settings.barcodeFormatIds = EnumBarcodeFormat.BF_PDF417;
      settings.localizationModes = [16, 8, 2, 0, 0, 0, 0, 0];
      settings.deblurLevel = 7;
      await scanner.updateRuntimeSettings(settings);
      await scanner.setUIElement(scannerRef.current || "");
      scanner.onFrameRead = results => {
        //should we be interested in these or just unque reading
      };
      scanner.onUniqueRead = (txt, result) => {
        handleResults(txt, result);
      }
      await scanner.open();
    }
    catch (ex) {
      console.error(ex);
    }
  }

  const cleanUp = async () => {
    if (scanner) {
      await scanner.destroyContext();
      scanner = null as any;
      console.log('BarcodeScanner Component Unmount');
    }
  }

  const initBeep = () => {
    audio = new Audio(SOUNDS_MAPPING.beep.file);
  }

  useEffect(() => {
    initBeep();
  }, []);

  useEffect(() => {
    if (scannerOpen) {
      initScanner();
    } else {
      cleanUp();
    }

    return () => {
      cleanUp();
    }
  }, [scannerOpen]);

  const handleResults = (txt: string, result: TextResult) => {
    const personInfo = new DriverLicenseParser(result.barcodeText).parse();
    if (beep) {
      audio.play();
    }
    onSuccessfullScan?.(personInfo);
    setScannerOpen(false);
  }

  const handleClose = () => {
    setScannerOpen(false);
  }

  return (
    <Fragment>
      <Box className="scanner-switch">
        <Button variant="outlined" style={{borderRadius: "50px", marginTop: "10px"}} onClick={() => setScannerOpen(!open)}>
          <QrCode2 htmlColor="black" fontSize="large" titleAccess={title}/>
          <p style={{float: "right", lineHeight: "10px"}}>Scanner</p>
        </Button>
      </Box>
      <Dialog open={scannerOpen} disablePortal style={{ zIndex: 10000001 }} onClick={(e) => e.stopPropagation()}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div ref={scannerRef} className="component-barcode-scanner">
              <svg className="dce-bg-loading" viewBox="0 0 1792 1792">
                <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path>
              </svg>
              <div className="dce-video-container"></div>
              <div className="dce-scanarea">
                <div className="dce-scanlight" style={{ display: "none" }}></div>
              </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button style={dialogButton} onClick={handleClose} variant="outlined" color="primary" >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
export default DynamsoftScanner;
