import React, { Fragment } from "react";
import { IntakeForm } from "../components/IntakeForm";
import { AppVersion } from "./AppVersion";

export const App = () => {
    return (
        <Fragment>
            <IntakeForm></IntakeForm>
            <AppVersion></AppVersion>
        </Fragment>
    );
}