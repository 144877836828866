import React, { useCallback, useEffect, useState } from 'react';
import packageInfo from '../../version.json';

export const AppVersion = () => {
  const [showVersion, setShowVersion] = useState(false);
  const [keyPressCount, setKeyPressCount] = useState(0);

  const onKeyPress = useCallback(
    (e: any) => {
      if (e.keyCode === 86 && keyPressCount < 5) {
        setKeyPressCount((prevState) => prevState + 1);
      } else if (keyPressCount !== 0) {
        setKeyPressCount(0);
      }
    },
    [keyPressCount]
  );

  useEffect(() => {
    if (keyPressCount === 5 && !showVersion) {
      setShowVersion(true);
    }
  }, [showVersion, keyPressCount]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [onKeyPress]);

  return (
    <React.Fragment>
      {showVersion && (
        <div>
          App Version: {packageInfo.appVersion}
          <br/>
          Build Version: {packageInfo.buildVersion}
        </div>
      )}
    </React.Fragment>
  );
};
