import { DRIVERS_LICENSE_FORMAT_PDF417 } from "./Constants";

export interface PersonInfo {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  dateOfBirth?: string;
  gender?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  ssn?: string;
}
export class DriverLicenseParser {
  barcodeText: string;

  constructor(barcodeText: string) {
    this.barcodeText = barcodeText;
  }

  parse(): PersonInfo {
    const personInfo: PersonInfo = {};
    for (let i = 0; i < DRIVERS_LICENSE_FORMAT_PDF417.length; i++) {
      const item = DRIVERS_LICENSE_FORMAT_PDF417[i];
      const fieldValue = this.getField(item.abbreviation);
      if (fieldValue !== false) {
        switch (item.abbreviation) {
          case "DAC":
            personInfo.firstName = fieldValue;
            break;
          case "DCS":
            personInfo.lastName = fieldValue;
            break;
          case "DBB":
            personInfo.dateOfBirth = fieldValue;
            break;
          case "DBC":
            personInfo.gender = fieldValue;
            break;
          case "DBM":
            personInfo.ssn = fieldValue;
            break;
          case "DAG":
            personInfo.address1 = fieldValue;
            break;
          case "DAH":
            personInfo.address2 = fieldValue;
            break;
          case "DAI":
            personInfo.city = fieldValue;
            break;
          case "DAJ":
            personInfo.state = fieldValue;
            break;
          case "DAK":
            personInfo.zip = fieldValue;
            break;
        }
      }
    }
    return personInfo;
  }
  private getField(keyword: string) {
    const k = this.barcodeText.search("\n" + keyword);
    if (k == -1) return false;
    const m = this.barcodeText.indexOf("\n", k + 1);
    const subtext = this.barcodeText.substring(k + 4, m);
    return subtext;
  }
}
