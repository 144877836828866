const DRIVERS_LICENSE_FORMAT_PDF417 = [
  {
    abbreviation: "DAA",
    description: "Full Name",
  },
  {
    abbreviation: "DAB",
    description: "Last Name",
  },
  {
    abbreviation: "DAC",
    description: "First Name",
  },
  {
    abbreviation: "DAD",
    description: "Middle Name",
  },
  {
    abbreviation: "DAE",
    description: "Name Suffix",
  },
  {
    abbreviation: "DAF",
    description: "Name Prefix",
  },
  {
    abbreviation: "DAG",
    description: "Mailing Street Address1",
  },
  {
    abbreviation: "DAH",
    description: "Mailing Street Address2",
  },
  {
    abbreviation: "DAI",
    description: "Mailing City",
  },
  {
    abbreviation: "DAJ",
    description: "Mailing Jurisdiction Code",
  },
  {
    abbreviation: "DAK",
    description: "Mailing Postal Code",
  },
  {
    abbreviation: "DAL",
    description: "Residence Street Address1",
  },
  {
    abbreviation: "DAM",
    description: "Residence Street Address2",
  },
  {
    abbreviation: "DAN",
    description: "Residence City",
  },
  {
    abbreviation: "DAO",
    description: "Residence Jurisdiction Code",
  },
  {
    abbreviation: "DAP",
    description: "Residence Postal Code",
  },
  {
    abbreviation: "DAQ",
    description: "License or ID Number",
  },
  {
    abbreviation: "DAR",
    description: "License Classification Code",
  },
  {
    abbreviation: "DAS",
    description: "License Restriction Code",
  },
  {
    abbreviation: "DAT",
    description: "License Endorsements Code",
  },
  {
    abbreviation: "DAU",
    description: "Height in FT_IN",
  },
  {
    abbreviation: "DAV",
    description: "Height in CM",
  },
  {
    abbreviation: "DAW",
    description: "Weight in LBS",
  },
  {
    abbreviation: "DAX",
    description: "Weight in KG",
  },
  {
    abbreviation: "DAY",
    description: "Eye Color",
  },
  {
    abbreviation: "DAZ",
    description: "Hair Color",
  },
  {
    abbreviation: "DBA",
    description: "License Expiration Date",
  },
  {
    abbreviation: "DBB",
    description: "Date of Birth",
  },
  {
    abbreviation: "DBC",
    description: "Sex",
  },
  {
    abbreviation: "DBD",
    description: "License or ID Document Issue Date",
  },
  {
    abbreviation: "DBE",
    description: "Issue Timestamp",
  },
  {
    abbreviation: "DBF",
    description: "Number of Duplicates",
  },
  {
    abbreviation: "DBG",
    description: "Medical Indicator Codes",
  },
  {
    abbreviation: "DBH",
    description: "Organ Donor",
  },
  {
    abbreviation: "DBI",
    description: "Non-Resident Indicator",
  },
  {
    abbreviation: "DBJ",
    description: "Unique Customer Identifier",
  },
  {
    abbreviation: "DBK",
    description: "Social Security Number",
  },
  {
    abbreviation: "DBL",
    description: "Date Of Birth",
  },
  {
    abbreviation: "DBM",
    description: "Social Security Number",
  },
  {
    abbreviation: "DBN",
    description: "Full Name",
  },
  {
    abbreviation: "DBO",
    description: "Last Name",
  },
  {
    abbreviation: "DBP",
    description: "First Name",
  },
  {
    abbreviation: "DBQ",
    description: "Middle Name",
  },
  {
    abbreviation: "DBR",
    description: "Suffix",
  },
  {
    abbreviation: "DBS",
    description: "Prefix",
  },
  {
    abbreviation: "DCA",
    description: "Virginia Specific Class",
  },
  {
    abbreviation: "DCB",
    description: "Virginia Specific Restrictions",
  },
  {
    abbreviation: "DCD",
    description: "Virginia Specific Endorsements",
  },
  {
    abbreviation: "DCE",
    description: "Physical Description Weight Range",
  },
  {
    abbreviation: "DCF",
    description: "Document Discriminator",
  },
  {
    abbreviation: "DCG",
    description: "Country territory of issuance",
  },
  {
    abbreviation: "DCH",
    description: "Federal Commercial Vehicle Codes",
  },
  {
    abbreviation: "DCI",
    description: "Place of birth",
  },
  {
    abbreviation: "DCJ",
    description: "Audit information",
  },
  {
    abbreviation: "DCK",
    description: "Inventory Control Number",
  },
  {
    abbreviation: "DCL",
    description: "Race Ethnicity",
  },
  {
    abbreviation: "DCM",
    description: "Standard vehicle classification",
  },
  {
    abbreviation: "DCN",
    description: "Standard endorsement code",
  },
  {
    abbreviation: "DCO",
    description: "Standard restriction code",
  },
  {
    abbreviation: "DCP",
    description: "Jurisdiction specific vehicle classification description",
  },
  {
    abbreviation: "DCQ",
    description: "Jurisdiction-specific",
  },
  {
    abbreviation: "DCR",
    description: "Jurisdiction specific restriction code description",
  },
  {
    abbreviation: "DCS",
    description: "Last Name",
  },
  {
    abbreviation: "DCT",
    description: "First Name",
  },
  {
    abbreviation: "DCU",
    description: "Suffix",
  },
  {
    abbreviation: "DDA",
    description: "Compliance Type",
  },
  {
    abbreviation: "DDB",
    description: "Card Revision Date",
  },
  {
    abbreviation: "DDC",
    description: "HazMat Endorsement Expiry Date",
  },
  {
    abbreviation: "DDD",
    description: "Limited Duration Document Indicator",
  },
  {
    abbreviation: "DDE",
    description: "Family Name Truncation",
  },
  {
    abbreviation: "DDF",
    description: "First Names Truncation",
  },
  {
    abbreviation: "DDG",
    description: "Middle Names Truncation",
  },
  {
    abbreviation: "DDH",
    description: "Under 18 Until",
  },
  {
    abbreviation: "DDI",
    description: "Under 19 Until",
  },
  {
    abbreviation: "DDJ",
    description: "Under 21 Until",
  },
  {
    abbreviation: "DDK",
    description: "Organ Donor Indicator",
  },
  {
    abbreviation: "DDL",
    description: "Veteran Indicator",
  },
  {
    abbreviation: "PAA",
    description: "Permit Classification Code",
  },
  {
    abbreviation: "PAB",
    description: "Permit Expiration Date",
  },
  {
    abbreviation: "PAC",
    description: "Permit Identifier",
  },
  {
    abbreviation: "PAD",
    description: "Permit IssueDate",
  },
  {
    abbreviation: "PAE",
    description: "Permit Restriction Code",
  },
  {
    abbreviation: "PAF",
    description: "Permit Endorsement Code",
  },
  {
    abbreviation: "ZVA",
    description: "Court Restriction Code",
  },
];

export { DRIVERS_LICENSE_FORMAT_PDF417 as DRIVERS_LICENSE_FORMAT_PDF417 };
