import moment from 'moment';

export function getObjectValue(obj: any, pathMetadata: any) : any {
    let path;
    if (pathMetadata instanceof Object) {
        path = pathMetadata.source;        
    } else {
        path = pathMetadata;
    }

    try {
        let i = 0;
        const pathArr = path.split('.');
        for (; i < pathArr.length - 1; i++) {
            obj = obj[pathArr[i]];
        }
    
        let value = obj[pathArr[i]];
        if (pathMetadata.dateFormat) {
            value = moment(value, pathMetadata.dateFormat).format("YYYY-MM-DD");
        }
        if (pathMetadata.enumMapping) {
            value = pathMetadata.enumMapping[value];
        }
        if (pathMetadata.plusFour !== undefined && !pathMetadata.plusFour && value.length > 5) {
            value = value.substring(0, 5);
        }
    
        return value    
    } catch (e) {
        return undefined;
    }
}

export function setObjectValue(obj: any, path : string, value : any) : void {
    let i = 0;
    const pathArr = path.split('.');
    for (; i < pathArr.length - 1; i++) {
        obj = obj[pathArr[i]]
    }
    obj[pathArr[i]] = value;
}

export function transformTitles(obj: any, schema: any, translations: any) : any {
    const keys = Object.keys(obj);
    keys.forEach(key => {
        let path = schema;
        if(key !== 'properties') {
            path = schema ? `${schema}.${key}` : key;
        }
        if(typeof obj[key] === 'object' && key !== 'enumNames') {
            transformTitles(obj[key], path, translations);
        } else if(typeof obj[key] === 'string' || key === 'enumNames') {
            const transKey = `translations.${path}`;
            if(translations && translations[transKey]) {
            obj[key] = translations[transKey];
            }
        } 
    });
    return obj;
  }
