import { Box, Button, CircularProgress, Grid, Snackbar, FormControl, Select, MenuItem } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/lab/Alert';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import React, { FC, CSSProperties, Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { local } from 'brownies';
import { DynamsoftScanner } from '../DynamsoftScanner';
import { isIOS, isIOS13, isIPad13 } from "react-device-detect";
import { getObjectValue, setObjectValue, transformTitles } from "../../utils/ObjectUtils";

const formStyle: CSSProperties = {
  marginLeft: "18%",
  marginRight: "18%",
  position: "relative"
}

const langDropdownStyle: CSSProperties = {
  right: "0.5em",
  marginTop: "0.5em",
  position: "absolute"
}

const formButtonStyle: CSSProperties = {
  minWidth: "200px",
  marginTop: "60px",
  marginBottom: "20px",
  marginRight: "10px"
};

const progressIndicatorContainerStyle: CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflowX: "hidden",
  background: "rgba(0, 0, 0, 0.1)",
  zIndex: 1
}

const progressIndicatorStyle: CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-50px",
  marginLeft: "-50px"
}

function inApp() {
  return window.webkit || window.ecforms;
}

function isNativeApp() {
  return isIOS || isIOS13 || isIPad13;
}

function showProgressIndicator(state: any, formData: any) {
  if (local.intakeFormSubmitted == "true" || (formData != null && state.submitted)) {
    return (
      <div style={progressIndicatorContainerStyle}>
        <CircularProgress size={50} style={progressIndicatorStyle} />
      </div>
    );
  }
}

function isDynamsoftScannerEnabled(form: any, license: string) {
  return form.scannerType && form.scannerType.findIndex((e:string) => {return e.toLowerCase() === 'dynamsoft'}) > -1 && license;
}

function enableDynamsoftScanner(form: any, formUi: any, setFormData: any, buttonRef: any, license: string) {
  if (form && isDynamsoftScannerEnabled(form, license) && form.inputDataMapping) {
    return (<DynamsoftScanner open={false} title={form.scannerTitle} license={license} onSuccessfullScan={(pi) => {populateWithInputData(pi, formUi, setFormData, buttonRef, form.disableScannerFields ,form.autoSubmitForm)}} />);
    return (<div></div>);
  }
}

function getFormData() {
  return JSON.parse(typeof local.intakeForm === undefined || local.intakeForm == null ? "{}" : local.intakeForm);
}

/**
 * Can be called when an input device (e.g. barcode scanner) is done generating the data
 * @param data is expected to be an Object.
 * @param setFormData is the function that sets the formData state.
 */
function populateWithInputData(data : any, formUi: any, setFormData: any, buttonRef: any, disableScannerFields: boolean, autoSubmitForm: boolean) {
  const formData = getFormData();
  if (local.deviceButton) {
    const intakeConfig = JSON.parse(local.deviceButton);
    const form = JSON.parse(intakeConfig.form);
    // inputDataMapping defines how the input device's data is mapped to the intake form fields
    if (form.inputDataMapping) {
      for (const [target, source] of Object.entries(form.inputDataMapping)) {
        setObjectValue(formData, target, getObjectValue(data, source));
        if (disableScannerFields) {
          setObjectValue(formUi, target + ".ui:readonly", true);
        }
      }
    }
    // Update formUi to set fields populated by the scanner to be readonly when disableScannerFields is true.
    // Note that fields should be defined in formUi.
    intakeConfig.formUi = JSON.stringify(formUi);
    local.deviceButton = JSON.stringify(intakeConfig);
  }
  setFormData(formData);
  if (autoSubmitForm) {
    setTimeout(()=> buttonRef.current.click(), 1000);
  }
}

function cleanup() {
  local.intakeForm = "{}";
  local.intakeFormSubmitted = "false";
}

type IAlertContent = {
  severity?: AlertProps["severity"];
  message?: string;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const IntakeForm: FC = () : ReactElement => {

  const buttonRef: any = useRef();
  const [formDetails, setFormDetails] = useState({form: "{}", formUi: "{}", formErrors: "{}", licenses: { dynamsoft: ""}});
  const [state, setState] = useState({ submitted: false });
  const [alert, setAlert] = useState<IAlertContent>({ message: "" });
  const [formData, setFormData] = useState({});
  const [formKey, setFormKey] = useState({key: Date.now()}); // workaround to have form errors cleared when backend processing fails
  const [language, setLanguage] = useState('');

  const saveForm = async (formData: any) => {
    local.intakeForm = JSON.stringify(formData);
  }

  const submitForm = async (formData: any) => {
    local.intakeFormSubmitted = "true";
    setState({ submitted: true });
    isNativeApp()  ? window.webkit.messageHandlers.submit.postMessage(formData) : window.ecforms.submit(formData);
  }

  const cancel = () => {
    isNativeApp()  ? window.webkit.messageHandlers.cancel.postMessage({}) : window.ecforms.cancel();
  }

  const transformErrors: any = (errors: any, customErrors: any) => {
    return errors.map((error: any) => {
      const key = error.name + error.property;
      const localizedMessages = customErrors[language];
      if (localizedMessages && key in localizedMessages) {
        error.message = localizedMessages[key];
        error.stack = error.message;
      }
      return error;
    });
  }

  const changeLanguage: any = (event: any) => {
    setLanguage(event.target.value);
  }

  const getFormDetailsWebkit = async () : Promise<any> => {
    const rp : Promise<any> = window.webkit.messageHandlers.getFormDetails.postMessage({});
    rp.then((formDetails) => {
      setFormDetails({form: formDetails.form, formUi: formDetails.formUi, formErrors: formDetails.formErrors, licenses: formDetails.licenses});
    });
  }

  const getFormDetailsElectron = () => {
    const formDetails = window.ecforms.getFormDetails();
    setFormDetails({form: formDetails.form, formUi: formDetails.formUi, formErrors: formDetails.formErrors, licenses: formDetails.licenses});
  }

  if (!inApp()) {
    return <div></div>;
  }

  const formId = "intakeForm";
  const messageEvent = "messageEvent";
  const form = JSON.parse(formDetails.form);
  const formUi = JSON.parse(formDetails.formUi);
  const license = formDetails.licenses ? formDetails.licenses.dynamsoft : "";
  const supportedLanguages = form.languages ? form.languages : [];
  const formErrors = JSON.parse(formDetails.formErrors);

  useEffect(() => {    
    // workaround for when some form fields are not getting validated when the structure of the form is multilevel (i.e. form fields are grouped like 'demographics' and 'clinical')
    // this initializes the form data to include the top level keys of the JSON object.
    const formKeys = Object.keys(formUi);
    if (!formKeys.includes('ui:order')) {  // only do it if the form has groupings which means it wouldn't have ui:order available on the top level keys
      const initData: any = {};
      formKeys.forEach((fk) => {
        initData[fk] = {};
      });
      setFormData(initData);
    }
  }, [formDetails]);

  useEffect(() => {
    //Check if supported languages includes navigator.language or not and set default language
    if(supportedLanguages.length > 0 && !language) {
      const systemLanguage = supportedLanguages.find((lan: any) => lan.lang_code === navigator.language)?.lang_code;
      setLanguage(systemLanguage || 'en-US');
    } else if (!language) {
      setLanguage('en-US');
    }
  }, [supportedLanguages, language]);

  useEffect(() => {
    return () => {
      cleanup();
    }
  });

  useEffect(() => {
    isNativeApp() ? getFormDetailsWebkit() : getFormDetailsElectron();
  }, []);

  useEffect(() => {
    // This allows the form to display an error message coming from the linkRequest response
    document.getElementById(formId).addEventListener(messageEvent, (event: any) => {
      local.intakeFormSubmitted = "false";
      setAlert({ severity: "error", message: `${event.detail.message}` });
      setState({ submitted: false });
      setFormKey({key: Date.now()});
    })
    return () => {
      document.getElementById(formId).removeEventListener(messageEvent, () => {
        //
      });
    }
  });

  return (
    <Fragment>
      {enableDynamsoftScanner(form, formUi, setFormData, buttonRef, license)}
      {supportedLanguages?.length > 0 &&
        <FormControl style={langDropdownStyle}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label={supportedLanguages.find((lang: any) => lang.lang_code === language)?.description}
            onChange={changeLanguage}
          >
            {supportedLanguages.map((lang: any) =>
              <MenuItem key={lang.lang_code}
                value={lang.lang_code}>
                  {lang.description}
              </MenuItem>
            )}

          </Select>
        </FormControl>
      }
      <Box display="flex" justifyContent="center" style={{marginTop: "2%", ...formStyle}}>
        <Form id={formId}
          key={formKey.key}
          schema={transformTitles(form, '', formErrors[language])}
          uiSchema={formUi}
          formData={formData}
          showErrorList={false}
          transformErrors={(errors : any) => transformErrors(errors, formErrors)}
          validator={validator}
          noHtml5Validate
          onChange={({ formData } : any, e) => saveForm(formData)}
          onSubmit={({ formData } : any, e) => submitForm(formData)}>
          <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginBottom: "60px" }}>
            <Button ref={buttonRef} style={formButtonStyle} variant="contained" color="primary" type="submit" form="intakeForm">
              {form['submitButtonText']}
            </Button>
            <Button style={formButtonStyle} variant="outlined" color="primary" onClick={() => cancel()}>
              {form['cancelButtonText']}
            </Button>
          </Grid>
        </Form>
        <Snackbar style={{ marginBottom: 50 }} open={alert.message != ""} autoHideDuration={6000} onClose={() => setAlert({ message: "" })}>
          <Alert severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
        {showProgressIndicator(state, formData)}
      </Box >
    </Fragment>
  );  

}